<template lang="">
  <div id="js-loader" class="loader"><div class="loader-animation"></div></div> <div
  class="maincontent" id="content" style="min-height: 618px"> <div
  class="maincontentinner" style="opacity: 1; left: 0px; transition: all 0.5s ease 0s" >
  <div class="mt_wrap"> <h1 class="ttl_1">集計ページ<span></span></h1> <nav
  class="breadcrumb_wrap"> <ul class="breadcrumb"> <li> <router-link to="/admin/news/">
  ダッシュボード </router-link> </li> <li><span>集計ページ</span></li> </ul> </nav> </div>
  <Spinner v-if="spinner" /> <div class="contentout_wrap"> <div class="content_wrap"> <div
  class="box_wrap"> <div class="box_header"> <h2 class="ttl_2 left
  m_r20">集計ページ一覧</h2> <div class="form_outwrap left"> <div class="wrap wid_200px
  m_b0"> <div class="in_wrap"> <div class="select_wrap min"> <select
  name="list_type_select" v-model="finYear" @change="changeYear($event)" >
  <option value="">すべて</option> <!-- <option value="2021">49期（2021）</option> -->
  <option value="2022">49期（2022）</option> <option value="2023">50期（2023）</option>
  <option value="2024">51期（2024）</option> <option value="2025">52期（2025）</option>
  <option value="2026">53期（2026）</option> </select> </div> </div> </div> <div
  class="wrap wid_200px m_b0"> <div class="in_wrap"> <div class="select_wrap min"> <select
  name="list_type_select" v-model="pageParam.searchMonth" @change="changeMonth($event)" >
  <option value="" :data-year="finYear"> 通年
  {{ finYear != "" ? "(" + finYear + ")" : "" }}
  </option> <option value="10" :data-year=" finYear - 1 != '' && finYear != 0 ?
  finYear - 1 : '' " > 10月
  {{ finYear - 1 != "" && finYear != 0 ? "(" + (finYear - 1) + ")" : "" }}
  </option> <option value="11" :data-year=" finYear - 1 != '' && finYear != 0 ?
  finYear - 1 : '' " > 11月
  {{ finYear - 1 != "" && finYear != 0 ? "(" + (finYear - 1) + ")" : "" }}
  </option> <option value="12" :data-year=" finYear - 1 != '' && finYear != 0 ?
  finYear - 1 : '' " > 12月
  {{ finYear - 1 != "" && finYear != 0 ? "(" + (finYear - 1) + ")" : "" }}
  </option> <option value="1" :data-year="finYear"> 1月 
    <!-- change financial year mismatch -->
  {{ finYear != "" ? "(" + finYear + ")" : "" }}
  </option> <option value="2" :data-year="finYear"> 2月
  {{ finYear != "" ? "(" + finYear + ")" : "" }}
  </option> <option value="3" :data-year="finYear"> 3月
  {{ finYear != "" ? "(" + finYear + ")" : "" }}
  </option> <option value="4" :data-year="finYear"> 4月
  {{ finYear != "" ? "(" + finYear + ")" : "" }}
  </option> <option value="5" :data-year="finYear"> 5月
  {{ finYear != "" ? "(" + finYear + ")" : "" }}
  </option> <option value="6" :data-year="finYear"> 6月
  {{ finYear != "" ? "(" + finYear + ")" : "" }}
  </option> <option value="7" :data-year="finYear"> 7月
  {{ finYear != "" ? "(" + finYear + ")" : "" }}
  </option> <option value="8" :data-year="finYear"> 8月
  {{ finYear != "" ? "(" + finYear + ")" : "" }}
  </option> <option value="9" :data-year="finYear"> 9月
  {{ finYear != "" ? "(" + finYear + ")" : "" }}
  </option> </select> </div> </div> </div> </div> </div> <!--tool_wrap--> <div
  class="tool_wrap"> <div class="bulktool"> <div class="m_r50"> <span
  class="selectednum"></span><span>が選択済みです</span> </div> <div class="delete
  commandtext js-modal-open"> <button value="アーカイブ" class="" dusk="delete_data">
  アーカイブ </button> </div> </div> <div id="drawer_bg" class="show"></div> <div
  class="command_wrap_inbox"> <div class="leftarea"></div> <!--leftarea--> <div
  class="rightarea"> <div class="btn_mic form_btn csv_btn"> <button @click="exportCsv()"
  style="cursor: pointer; margin: 10px" type="submit" > CSV出力 </button> </div> <!--
  <Pagination /> --> <ul v-if="pagination.total > 500" class="pagination"
  role="navigation" > <li class="page-item" style="cursor: pointer" v-bind:class="[
  pagination.current_page == 1 ? 'disabled' : '', ]"
  @click="setPage(pagination.current_page - 1)" rel="prev" aria-label="« 前へ" > <span
  class="page-link" aria-hidden="true">‹</span> </li> <li style="cursor: pointer"
  class="page-item" aria-current="page" v-for="n in pagination.last_page" :key="n.index"
  v-bind:class="[ pagination.current_page == n ? 'active' : '', ]" @click="setPage(n)" >
  <span class="page-link">{{ n }}</span> </li> <li style="cursor: pointer"
  class="page-item" aria-label="次へ »" @click="setPage(pagination.current_page + 1)"
  v-bind:class="[ pagination.last_page == pagination.current_page ? 'disabled' : '', ]" >
  <span class="page-link" aria-hidden="true">›</span> </li> </ul> </div> <!--rightarea-->
  </div> <!--command_wrap--> </div> <form> <div class="scr_outwrap"> <div
  class="scr_wrap"> <div class="scr_wrap"> <table v-if="thanksSummary.length > 0"
  class="tbl"> <tbody> <tr> <th class="wid_30per t_l">ユーザー名</th> <th
  class="wid_10per">部門</th> <th class="wid_10per">書いた数</th> <th class="dtheader
  wid_15per">もらった数</th> <th class="dtheader wid_15per">気づき日報投稿数</th> <th
  class="dtheader wid_15per">コラム投稿数</th> </tr> <!-- <tr class="nopublic"> --> <tr
  v-for="thanks in thanksSummary.slice().reverse()" :key="thanks.id" > <td class="v_m
  p_t15 p_b15"> <span >{{ thanks ? thanks.last_name : "" + " " }}
  {{ thanks ? thanks.first_name : "" }}</span > </td> <td class="t_c v_m"> <span>{{
    thanks ? thanks.group_name : ""
  }}</span> </td> <td class="t_c v_m"> <span>
  {{ thanks.sender != 0 ? thanks.sender : "" }}</span > </td> <td class="date t_r">
  <span>{{ thanks.reciver != 0 ? thanks.reciver : "" }}</span> </td> <td class="date t_r">
  <span>{{ thanks.daily_count != 0 ? thanks.daily_count : "" }}</span> </td> <td
  class="date t_r"> <span>{{ thanks.column_count != 0 ? thanks.column_count : "" }}</span>
  </td> </tr> </tbody> </table> <p v-else>検索結果はありません</p> <!--tbl--> </div>
  <!--tbl--> </div> <!--scr_wrap--> </div> <!--scr_outwrap--> </form> <div
  class="command_wrap_inbox m_t10 m_b30"> <div class="rightarea"> <ul
  v-if="pagination.total > 500" class="pagination" role="navigation" > <li
  class="page-item" style="cursor: pointer" v-bind:class="[ pagination.current_page == 1 ?
  'disabled' : '', ]" @click="setPage(pagination.current_page - 1)" rel="prev"
  aria-label="« 前へ" > <span class="page-link" aria-hidden="true">‹</span> </li> <li
  style="cursor: pointer" class="page-item" aria-current="page" v-for="n in
  pagination.last_page" :key="n.index" v-bind:class="[ pagination.current_page == n ?
  'active' : '', ]" @click="setPage(n)" > <span class="page-link">{{ n }}</span> </li> <li
  style="cursor: pointer" class="page-item" aria-label="次へ »"
  @click="setPage(pagination.current_page + 1)" v-bind:class="[ pagination.last_page ==
  pagination.current_page ? 'disabled' : '', ]" > <span class="page-link"
  aria-hidden="true">›</span> </li> </ul> </div> <!--rightarea--> </div>
  <!--command_wrap--> </div> <!--box_wrap--> </div> <!--content_wrap--> </div>
  <!--contentoutwrap--> </div> <!--maincontentinner--> </div>
</template>
<script>
import Spinner from "../../../components/control_panel/Spinner.vue";
import "@/assets/front_component/js/timepicker/jquery.ui.datepicker-ja.min.js";
import User from "@/apis/User";
import Thanks from "@/apis/Thanks";
import { mapState, mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Thanks",
  data() {
    return {
      pageParam: {
        pageNo: 1,
        perPageCount: 25,
        searchYear: new Date().getFullYear(),
        searchMonth: new Date().getMonth() + 1,
      },
      currentYear: new Date().getFullYear(),
      finYear:((new Date().getMonth() + 1) == 10 || (new Date().getMonth() + 1) == 11 || (new Date().getMonth() + 1) == 12) ? new Date().getFullYear() + 1 : new Date().getFullYear(),
      serachData: {},
      thankMembers: [],
      thanksSummary: [],
      pagination: {},
    };
  },
  computed: {
    ...mapState("common", ["spinner"]),
  },
  watch: {
    async thankMembers(thanks) {
      this.thanksSummary = thanks;
    },
  },
  components: {
    Spinner,
  },
  created() {
    this.setPaginationscreen("thanks-summary");
    this.getAllThanks();
    // this.getAllThanks1();
  },
  methods: {
    ...mapActions("common", ["setSpinnerStatus", "setPaginationscreen"]),

    getAllThanks() {
      this.setSpinnerStatus(true);
      return Thanks.list(this.pageParam, "")
        .then(async (response) => {
          if (response != "") {
            this.thankMembers = response.data.data;
            this.pagination = response.data;
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.setSpinnerStatus(false);
        });
    },
    setPage(page) {
      this.pageParam.pageNo = page;
      this.getAllThanks();
    },
    changeYear: function (event) {
      if (event.target.value != "") {
        
        let currentMonth = this.pageParam.searchMonth;
        console.log(this.pageParam.searchYear);

        if(currentMonth == 10 || currentMonth == 11 || currentMonth == 12){
          
          this.pageParam.searchYear = event.target.value -1 ;
        }else{

          this.pageParam.searchYear = event.target.value ;
        }
      } else {
          this.pageParam.searchYear = "";
      }
      this.getAllThanks();
    },
    changeMonth: function (e) {
      // if(e.target.value == 10 || e.target.value == 11 || e.target.value == 12){
        this.pageParam.searchYear =
               e.target.options[e.target.options.selectedIndex].dataset.year;
      //   }else{
      //   this.pageParam.searchYear =this.finYear;
      // }
      this.pageParam.searchMonth = e.target.value;
      this.getAllThanks();

      if (this.pageParam.searchYear == new Date().getFullYear() - 1) {
        this.pageParam.searchYear = new Date().getFullYear();
      }
    },

    exportCsv() {
      if (
        this.pageParam.searchMonth == 10 ||
        this.pageParam.searchMonth == 11 ||
        this.pageParam.searchMonth == 12
      ) {
          this.pageParam.searchYear = this.finYear - 1;
      }else{
        this.pageParam.searchYear = this.finYear;
      }

      this.setSpinnerStatus(true);
      return Thanks.export(this.pageParam)
        .then(async (response) => {
          if (response != "") {
            if (this.pageParam.searchYear == new Date().getFullYear() - 1) {
              this.pageParam.searchYear = new Date().getFullYear();
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "集計ページ.csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.setSpinnerStatus(false);
        });
    },
  },
  mounted() {
    var vueThis = this;
  },
};
</script>
<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
</style>
